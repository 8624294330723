/* eslint-disable consistent-return */
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useFirebase } from 'lib/hooks/useFirebase';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import * as R from 'ramda';
import Promise from 'bluebird';
import { toast } from 'react-toastify';
import { getStoredNotifications, setStoredNotifications } from 'utils/notificationCheck';
import { useNotaries } from 'lib/redux/slices/notaries';
import debug from 'debug';
import { useRequireAuth } from './useAuth';
import { useApi } from './useApi';

const log = debug('hooks:useListenPresence');

const PresenceContext = createContext();

export function ListenPresence({ children, ...props }) {
  const dispatch = useDispatch();
  const queue = useSelector((state = {}) => state.queue);
  const [attached, setAttached] = useState(false);

  const { byId } = useNotaries();
  const authUtils = useRequireAuth();
  const auth = authUtils.getAuth();
  const api = useApi();
  const orgId = auth?.user?.organizationId;

  const connectionRef = useRef();
  const presenceRef = useRef();
  const sessionsRef = useRef();
  const firebase = useFirebase();

  const handleNotaryChange = useCallback(async (data) => {
    try {
      const val = data.val();

      log('notary changed', val, data.key);

      if (!val.notaryId) {
        return;
      }


      const existing = _.find(_.values(byId), { notaryId: val.notaryId });


      if (existing) {
        log('notary exists', existing);
        dispatch({
          type: 'queue/addOrUpdateNotary',
          payload: {
            id: existing.id,
            page: val.page,
            notaryId: existing.notaryId,
            nsId: val.nsId,
            connected: val.connected,
            user: existing,
            notary: existing.notary,
          },
        });

        return;
      }

      const exists = await api.getNotaryExists(val.notaryId, auth.token).then(R.prop('data'));

      if (!exists) {
        return;
      }


      const [notary, user] = await Promise.all([
        api.getNotary(val.notaryId).then(R.prop('data')),
        api.getNotaryUser(val.notaryId).then(R.prop('data')),
      ]);

      dispatch({
        type: 'queue/addOrUpdateNotary',
        payload: {
          id: user.id,
          page: val.page,
          notaryId: val.notaryId,
          nsId: val.nsId,
          connected: val.connected,
          user,
          notary,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }, [auth.token, dispatch, byId]);

  const handleSessionChange = useCallback(async (data) => {
    try {
      let val = data.val();
      const id = val.nsId;
      let participants;
      let docs;

      const session = queue.sessions[data.key];
      const { page } = val;

      if (!session) {
        // const { data: { exists } } = await getNsExists(id);

        // if (!exists) {
        //   return;
        // }
        const { data: [ns] } = await api.getNotarySessions(1, 50, {
          ids: [id],
        });

        let from = _.get(ns, 'organization.name');
        const assignedToOrg = _.get(ns, 'request.assignedToOrg');
        const assignedTo = _.get(ns, 'request.assignedTo');
        const isNotarizeNow = ns?.senderId === null && assignedToOrg === null && assignedTo === null;

        if (!_.isNil(assignedToOrg)) {
          from = _.get(ns, 'organization.name');
        } else if (isNotarizeNow) {
          from = 'Notarize Now';
        }

        if (id) {
          const data = await api.getNsParticipants(id, {
            params: {
              type: ['consumer', 'signer'],
            },
          });

          const docData = await api.getNsDocuments(id);

          docs = docData;
          participants = data;
          val = { from, ...ns, ...val };
        }
      } else {
        participants = queue.sessions[data.key].participants;
      }

      // TODO: Move to a feature flag??

      if (page === 'waiting-room') {
        const primarySigner = R.find(R.propEq('type', 'consumer'))(participants);

        const primarySignerId = primarySigner.id;
        const seenNotifications = getStoredNotifications();

        if (R.isEmpty(seenNotifications)) {
          setStoredNotifications(primarySignerId);
          toast.success(`${primarySigner.user.firstName} ${primarySigner.user.lastName} is ready and in the waiting room.`);
        } else if (!seenNotifications.includes(primarySignerId)) {
          setStoredNotifications(primarySignerId);
          toast.success(`${primarySigner.user.firstName} ${primarySigner.user.lastName} is ready and in the waiting room.`);
        }

        // TODO: Build a HOC that controls toast AND sound because for this sound notification they need to be tied together.
        // playWaitingRoomNotification();
      }

      dispatch({
        type: 'queue/addOrUpdateSession',
        payload: {
          id: data.key,
          participants,
          docs,
          ...val,
        },
      });
    } catch (error) {
      console.debug(error);
    }
  }, [dispatch, auth.token, queue.sessions]);


  const attachPresence = useCallback(() => {
    log('ATTACHING PRESENCE');

    if (!(auth.user && auth.user.id && !attached)) {
      return;
    }

    const connRef = firebase.database().ref('.info/connected');
    const orgRef = firebase.database().ref(`/organization/${orgId}`);

    connectionRef.current = connRef;

    const notaryRef = orgRef.child('notaries');
    const sortedNRef = notaryRef;

    // .orderByChild('connected').equalTo(true);
    if (!auth.user) {
      return;
    }

    const pRef = notaryRef.child(auth.user.id);

    presenceRef.current = pRef;

    const sRef = orgRef
      .child('sessions')
      .orderByChild('connected')
      .equalTo(true);

    sessionsRef.current = sRef;

    const attachListeners = async () => {
      // get initial connected notaries
      const data = await sortedNRef
        .orderByChild('connected')
        .equalTo(true)
        .once('value');
      const notaries = data.val();


      dispatch({
        type: 'queue/loadNotaries',
        payload: notaries,
      });


      const currentSessions = (await sRef.once('value')).val();


      if (_.keys(currentSessions).length > 0) {
        const { data } = await api.getNotarySessions(1, 50, {
          ids: _.keys(currentSessions),
        });

        _.map(data, ({ id, notarySessionUsers, docs, ...ns }) => {
          const val = currentSessions[id];
          let from = _.get(ns, 'organization.name');
          const assignedToOrg = _.get(ns, 'request.assignedToOrg');
          const assignedTo = _.get(ns, 'request.assignedTo');
          const isNotarizeNow = ns?.senderId === null && assignedToOrg === null && assignedTo === null;

          if (!_.isNil(assignedToOrg)) {
            from = _.get(ns, 'organization.name');
          } else if (isNotarizeNow) {
            from = 'Notarize Now';
          }

          dispatch({
            type: 'queue/addOrUpdateSession',
            payload: {
              id,
              participants: notarySessionUsers,
              docs,
              from,
              ...val,
            },
          });
        });
      }

      sRef.on('child_added', handleSessionChange);
      sRef.on('child_changed', handleSessionChange);
      sRef.on('child_removed', async (data) => {
        const val = data.val();
        const sessions = (await sRef.once('value')).val() || {};
        const sessionsActives = Object.values(sessions).filter((sa) => {
          if (sa.nsId === val.nsId && sa.nsId !== undefined && val.nsId !== undefined) {
            return sa;
          }
        });

        if (sessionsActives.length === 0) {
          dispatch({
            type: 'queue/sessionDisconnected',
            payload: { id: val.nsId },
          });
        }
      });

      sortedNRef.on('child_added', handleNotaryChange);
      sortedNRef.on('child_changed', handleNotaryChange);
      sortedNRef.on('child_removed', handleNotaryChange);
    };


    connRef.on('value', async (snapshot) => {
      if (snapshot.val() === false) {
        log('firebase not connected 🔥!');

        return;
      }

      log('firebase connected 🔥!');

      try {
        await pRef
          .child('connected')
          .onDisconnect()
          .set(false);

        const presenceState = _.pickBy({
          page: props.page,
          notaryId: auth.notaryId,
          nsId: props.nsId,
          connected: true,
        }, _.identity);

        await pRef.set(presenceState);
        await attachListeners();
        setAttached(() => true);
      } catch (error) {
        console.error(error);
      }
    });

    return () => {
      sortedNRef.off();
    };
  }, [auth.user, auth.notaryId, attached, firebase, orgId, dispatch, handleNotaryChange, handleSessionChange, api, props.page, props.nsId]);

  const detachPresence = useCallback(() => {
    log('%cfirebase detaching presence 🔥!');


    if (connectionRef.current && connectionRef.current.off) {
      // presenceRef.current.remove();
      connectionRef.current.off();
    }

    if (presenceRef.current && presenceRef.current.off) {
      // presenceRef.current.remove();
      presenceRef.current.off();
    }

    if (sessionsRef.current && sessionsRef.current.off) {
      // presenceRef.current.remove();
      sessionsRef.current.off();
    }
  }, []);

  const updatePage = useCallback(async (page, nsId) => {
    if (presenceRef.current && presenceRef.current.set && attached) {
      const currVal = (await presenceRef.current.once('value')).val();
      const currPage = _.get(currVal, 'page', []);

      if (_.difference(_.uniq([...currPage, page]), currPage).length === 0) {
        return;
      }

      const presenceState = _.pickBy({
        page: _.uniq([...currPage, page]),
        notaryId: auth.notaryId,
        nsId,
        connected: true,
      }, _.identity);

      presenceRef.current.set(presenceState);
    }
  });

  useEffect(() => {
    if (!attached && auth.isAuthenticated) {
      attachPresence();
    }

    if (!auth.isAuthenticated && auth.isInitialised) {
      setAttached(false);

      return detachPresence();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAuthenticated, auth.isInitialised]);


  const ctx = {
    refs: {
      connection: connectionRef.current,
      presence: presenceRef.current,
    },
    isLoading: auth.isLoading,
    attached,
    queue,
    updatePage,
    sessions: _.filter(queue.sessions, { connected: true }),
    notaries: _.filter(queue.notaries, { connected: true }),
  };


  return (
    <PresenceContext.Provider value={ctx}>
      {children}
    </PresenceContext.Provider>
  );
}


export default function useListenPresence(props) {
  const ctx = useContext(PresenceContext);

  useEffect(() => {
    if (props.page && _.isString(props.page) && ctx.attached) {
      ctx.updatePage(props.page, props.nsId);
    }
  }, [ctx, props.nsId, props.page]);

  return ctx;
}
