import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const FirebaseContext = createContext();

export const useFirebase = () => {
  return useContext(FirebaseContext);
};

export const withFirebase = (Component) => (props) => {
  const firebase = useFirebase();

  return (
    <Component {...props} firebase={firebase} />
  );
};


export function FirebaseProvider({ client, children }) {
  return (
    <FirebaseContext.Provider value={client}>
      {children}
    </FirebaseContext.Provider>
  );
}

FirebaseProvider.propTypes = {
  client: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};
