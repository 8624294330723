export const getStoredNotifications = () => {
  try {
    const ids = JSON.parse(localStorage.getItem('notarySessionUserNotifications'));

    return ids || [];
  } catch (e) {
    return [];
  }
};

export const setStoredNotifications = (notarySessionUserId) => {
  try {
    if (!notarySessionUserId) {
      localStorage.removeItem('notarySessionUserNotifications');
    } else {
      const nsUserIds = getStoredNotifications();

      nsUserIds.push(notarySessionUserId);

      localStorage.setItem('notarySessionUserNotifications', JSON.stringify(nsUserIds));
    }
  } catch (e) {
    // Do nothing
  }
};

