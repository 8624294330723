import { useSelector, useDispatch } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';

import { useCallback, useMemo } from 'react';
import getConfig from 'next/config';
import axios from 'axios';
import * as R from 'ramda';
import _ from 'lodash';
import { call, put, takeLatest, select } from 'redux-saga/effects';

const { publicRuntimeConfig: conf } = getConfig();

if (conf.API_URL) {
  // axios.defaults.baseURL = conf.API_URL;
  // axios.defaults.withCredentials = true;
}

const toById = R.pipe(R.map(R.juxt([R.prop('id'), R.identity])), R.fromPairs);

const notariesSlice = createSlice({
  name: 'notaries',
  initialState: {
    byId: {},
    allIds: [],
    loading: false,
    total: 0,
  },
  reducers: {
    fetchNotaryByIdSuccess(state, action) {
      state.byId[action.payload.id] = action.payload;
    },
    fetchNotariesSuccess(state, action) {
      state.byId = toById(action.payload);
      state.allIds = R.keys(state.byId);

      return state;
    },
    setLoading: (state, action) => ({
      ...state,
      loading: !!action.payload,
    }),
    add: (state, action) => {
      const notaries = R.indexBy(R.prop('id'), _.map(action.payload, (u) => ({ ...u, notaryId: u?.notary?.id })));
      const updated = R.mergeDeepRight(state.byId, notaries);

      const allIds = _.uniq([...state.allIds, ..._.keys(notaries)]);

      return {
        ...state,
        byId: updated,
        total: allIds.length,
        allIds,
      };
    },
  },
});

const { reducer } = notariesSlice;

const Api = {
  getNotaries: async (params = { accountActive: true }) => {
    const { data: { notaries } } = await axios.get('/api/organization/notaries', {
      params,
    });

    return notaries;
  },
  // TODO: consolidate images
};

export const sagas = ({ api }) => {
  function* updateNotary(action) {
    try {
      const data = yield call(api.updateNotary, action.payload);

      yield put({
        type: 'notaries/updateNotarySuccess',
        payload: data,
      });
    } catch (e) {
      yield put({
        type: 'notaries/updateNotaryFailure',
        message: e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message,
      });
    }
  }

  function* fetchNotaryById(action) {
    try {
      const auth = yield select((state) => state.auth);
      const data = yield call(
        api.getNotaryById,
        action.payload.notaryId,
        auth.token
      );

      yield put({ type: 'notaries/fetchNotaryByIdSuccess', payload: data });
    } catch (e) {
      yield put({
        type: 'notaries/fetchNotaryByIdFailure',
        message: e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message,
      });
    }
  }

  function* fetchNotaries() {
    try {
      const auth = yield select((state) => state.auth);
      const data = yield call(Api.getNotaries, auth.token);

      yield put({ type: 'notaries/fetchNotariesSuccess', payload: data });
    } catch (e) {
      yield put({
        type: 'notaries/fetchNotariesFailure',
        message: e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message,
      });
    }
  }

  function* rootNotariesSagas() {
    yield takeLatest('notaries/fetchNotariesRequest', fetchNotaries);
    yield takeLatest('notaries/fetchNotaryByIdRequest', fetchNotaryById);
    yield takeLatest('notaries/updateNotaryRequest', updateNotary);
    yield takeLatest('notaries/updateNotarySuccess', fetchNotaryById);
  }

  return rootNotariesSagas;
};

export const selector = (state) => state.notaries;


export const getNotaries = () => async (dispatch, getState, { api }) => {
  dispatch(notariesSlice.actions.setLoading(true));
  const notaries = await api.getNotaries();

  dispatch(notariesSlice.actions.add(notaries));
  dispatch(notariesSlice.actions.setLoading(false));
};


export default reducer;

export function useNotaries() {
  const dispatch = useDispatch();
  const { allIds, total, byId, loading } = useSelector(selector);
  const notaries = useMemo(() => _.values(byId), [byId]);

  return {
    loading,
    total,
    byId,
    allIds,
    notaries,
    getNotaries: useCallback(() => {
      dispatch(getNotaries());
    }, [dispatch]),
  };
}
